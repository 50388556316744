import React from 'react'
const new_btn = require('../../assets/images/box.png');
const checkbox_check = require('../../assets/images/checkbox_check.png');
const tick = require('../../assets/Tab_Images/Text/tick.png');

const upload_img_e = require('../../assets/Tab_Images/Images/btn_FileOpen_Enabled.png');
const upload_img_d = require('../../assets/Tab_Images/Images/btn_FileOpen_Disabled.png');
const view_e = require('../../assets/componentImages/btn_View_Enabled-8.png');
const view_d = require('../../assets/componentImages/btn_View_Disabled-8.png');
const remove_e = require('../../assets/Tab_Images/Data_Excel/btn_LinkX_enabled-8.png');
const remove_d = require('../../assets/Tab_Images/Data_Excel/btn_LinkX_disabled-8.png');

const Data_Excel = (props) => {

    return (
        <div style={{ backgroundColor: '#EEEEEE', padding: 0,display:'flex,',
            width : "18.5vw",zIndex:3,paddingLeft:'2.5vw',minHeight:props.heightLayer}}>

            <div style={{display:'flex,',paddingTop:'1.7vw',flexDirection:'column'}}>

                <div style={row2}>
                            <input style={imgName} value={props.fName.length > 0 ? props.fName : 'Select CSV File'} disabled={true}
                                     type="text" align={'center'}
                            />
                        <div style={btn_Style}  data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <input style={{width:0,height:0}} id={'loadcsvFile'} type="file" name="csvLabel" accept=".csv" onChange={props.csv_onLoad}  />
                            <img onClick={()=>document.getElementById('loadcsvFile').click()}  style={img_row1_C1} alt='file picker'  src={upload_img_e}  />
                        </div>
                </div>

                <div style={{...row2_c,marginTop:'1vw',alignItems:'center',height:'2.66vw'}}>

                    <div style={props.fName.length > 0 ? {...row_div_style,cursor:'pointer'}:{...row_div_style,opacity:'0.5'}} onClick={props.fName.length > 0 ? props.onRowChange: null} >
                        {
                            props.loadFileRow ?
                                <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  /> : null
                        }

                    </div>
                    <div style={text_Style}>First row has field names</div>
                </div>
                <div style={{...row2_c,alignItems:'center',height:'2.66vw'}}>

                    <img alt={'view image'} src={props.fName.length > 0 ? view_e: view_d}
                         style={props.fName.length > 0 ? {...view_img_style,cursor:'pointer'}:view_img_style} onClick={props.fName.length > 0 ? props.showCSV: null} />
                    {/*    /!*<img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />*!/*/}
                    {/*</img>*/}
                    <div style={text_Style}>View File</div>
                </div>

                <div style={{...row2_c,alignItems:'center',height:'2.66vw'}}>

                    <img alt={'remove file'} src={props.fName.length > 0 ? remove_e: remove_d}
                         style={props.fName.length > 0 ? {...view_img_style,cursor:'pointer'}:view_img_style} onClick={props.fName.length > 0 ?props.resetPopup: null} />

                    <div style={text_Style}>Remove Data File</div>
                </div>

                <div style={{...row2_c,marginTop:'1vw',alignItems:'center',height:'2.66vw'}}>

                    <div style={props.fName.length > 0 ? {...row_div_style,cursor:'pointer'}:{...row_div_style,opacity:'0.5'}} onClick={()=>props.onTruncateText()} >
                        {
                            props.truncateState ?
                                <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  /> : null
                        }

                    </div>
                    <div style={text_Style}>Truncate display text</div>
                </div>

                <div style={{...row2_c,marginTop:'1vw',alignItems:'center',height:'2.66vw'}}>

                    <div style={props.fName.length > 0 ? {...row_div_style,cursor:'pointer'}:{...row_div_style,opacity:'0.5'}} onClick={()=>props.onLimitedColumns()} >
                        {
                            props.limitedColumnState ?
                                <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  /> : null
                        }

                    </div>
                    <div style={text_Style}>Limit Columns</div>
                </div>


            </div>



        </div>
    )

}
const row2_c = {
    display:'flex',flexDirection:'row',marginLeft:'0.1vw', height: '1.6vw',marginTop:'0.6vw',marginRight:'0.1vw'

};
const btn_Style_Img={
    width: '2vw',height: '1.5vw', display:'flex', justifyContent: 'center', alignItems: 'center',borderWidth:2, borderColor:'black'
};
const text_Style={
    marginLeft:'0.5vw',fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
};

const img_row1 = {
    width:'90%',height:'90%', cursor:'pointer'

};
const row2 = {
    display:'flex',flexDirection:'row',marginLeft:'0.1vw', height: 'auto',marginRight:'0.1vw'

};
const btn_Style={
    width: '2.66vw',height:'2.66vw', display:'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'#cccccc'
};
const imgName = {

    height: '2.5vw', width:"13vw",backgroundColor:'white',border:'none',
    fontSize:'1vw',fontStyle: 'italic',paddingLeft:'1vw',
    textAlign:'left',justifyContent:'center', alignItems:'center',fontFamily: 'verdana',
}
const img_row1_C1 = {
    width:'90%',height:'auto', cursor:'pointer'

};
const btn_Style_L2={
    width: '2.66vw',height:'2.66vw', display:'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'#cccccc'
};
const img_row1_C1_L2 = {
    width:'90%',height:'auto', cursor:'pointer'

};
const view_img_style={
    ...btn_Style_L2,width:'2.66vw',height:'2.66vw',
    display:'flex',justifyContent:'center',alignItems:'center'
}
const row_div_style = {
    ...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.66vw',height:'2.66vw',
    display:'flex',justifyContent:'center',alignItems:'center'
}
export default Data_Excel;
