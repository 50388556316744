import React,{useState,useEffect} from 'react';
import {Modal} from "antd";
import './ViewCSV_Style.css'
import cancelIcon from "../assets/images/black_cancel.png";


const ViewCSVQBP = (props) => {
    const [columnFirst,setColumnFirst] = useState([])
    const [qtyVal,setQtyVal] = useState([])
    const [overallQTY,setOverallQTY] = useState(0)
    const [filterSearch,setFilterSearch] = useState('')
    const [selectedColumn,setSelectedColumn] = useState('')
    const [shallowCopy,setShallowCopy] = useState([])
    const [pageScroll,setPageScroll] = useState(20)
    const [searchOn,setSearchOn] = useState(false)
    const [searchScroll,setSearchScroll] = useState(20)
    const [searchData,setSearchData] = useState([])

    // Handle search state
    useEffect(() => {
      if (searchOn) {
        newArrData();
      }
    }, [searchOn]);

    // Initialize qtyVal if tableData is present and qtyVal is empty
    useEffect(() => {
      if (props.tableData.length > 0 && qtyVal.length === 0) {
        const initialQty = Array(props.tableData.length).fill('');
        setQtyVal(initialQty);
      }
    }, [props.tableData, qtyVal]);

    // Update columnFirst and shallowCopy when tableData changes
   useEffect(() => {
      if(props.tableData) {
          if (props.tableData.length > 0) {
              const firstRow = props.tableData[0];

              if (columnFirst.length === 0 || shallowCopy !== firstRow) {
                  // Generate options for all columns after the first column
                  const options = firstRow.slice(1).map((col, index) => (
                      <option key={`column-option-${index}`} value={col}>
                          {col}
                      </option>
                  ));

                  setShallowCopy(firstRow); // Memoize the reference
                  // Set columnFirst starting from the second column (index 1)
                  setColumnFirst(options);
                  setSelectedColumn(firstRow[1])
              }
          }
      }
    }, [props.tableData, columnFirst, shallowCopy]);


    // useEffect(() => {
    //     // Update the document title using the browser API
    //
    //     if (props.tableData.length > 0 && qtyVal.length === 0){
    //
    //         let gx = []
    //         for (let i =0; i < props.tableData.length;i++){
    //             gx.push('')
    //         }
    //         setQtyVal(gx)
    //
    //     }
    //
    //         if ((props.tableData.length > 0 )) {
    //             if (columnFirst.length === 0 || JSON.stringify(shallowCopy) !== JSON.stringify(props.tableData[0])) {
    //                 let bx = []
    //                 let cx = ['QTY']
    //                 for (let i = 1; i < props.tableData[0].length; i++) {
    //                     let sp = props.tableData[0][i]
    //                         cx.push(sp)
    //                     var option = <option key={`column-option-${i+sp}`} value={sp}>{sp}</option>
    //                     bx.push(option)
    //                 }
    //                 setShallowCopy(cx)
    //                 setColumnFirst(bx)
    //             }
    //         }
    // },[searchOn,props.tableData,qtyVal,columnFirst])

    useEffect(()=>{
       if(selectedColumn !== ''){
         props.setSelectedColumnCSV(selectedColumn)

       } else {
         if(props.selectedColumnCSV !== ''){
          setSelectedColumn(props.selectedColumnCSV)
         }
       }

    },[])

    useEffect(()=>{
     if(selectedColumn !== ''){
       props.setSelectedColumnCSV(selectedColumn)
     }

    },[selectedColumn])

    const isNumeric = (str)=> {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) && !str.includes('.')  // ...and ensure strings of whitespace fail
    }


    const getColumnPosition = () => {
      if (Array.isArray(props.tableData)) {
          if (props.tableData.length > 0) {
              const firstRow = props.tableData[0];
              if (Array.isArray(firstRow) && selectedColumn.length > 0) {
                  for (let i = 1; i < firstRow.length; i++) {
                      if (selectedColumn === firstRow[i]) {
                          return i;
                      }
                  }
              }
          }
      }
      return null;
    };

    const newArrData = ()=>{
        if (props.tableData.length > 0 && columnFirst.length > 0 && searchOn){
            setSearchOn(false)
            let b = []
            let rx = Number(getColumnPosition())
            b.push(props.tableData[0])

            for (let i =1;i < props.tableData.length;i++){
                // if (props.tableData[i].length > 0){
                if (props.tableData[i][rx]) {
                    if (props.tableData[i][rx].toString().toLowerCase().includes(filterSearch.toString().toLowerCase())) {
                        b[i] = (props.tableData[i])
                    }
                }
                // }
            }
            setSearchData(b)


        }
        return  []



        }



    const newArrDataLoop = ()=>{
        if (props.tableData.length > 0 && columnFirst.length > 0){
            let b = []
            let rx = Number(getColumnPosition())

            for (let i =0;i < props.tableData.length;i++){
                // if (props.tableData[i].length > 0){
                 if (props.tableData[i][rx]) {
                     if (props.tableData[i][rx].toLowerCase().includes(filterSearch.toLowerCase())) {
                         b.push(i)
                     }
                 }
                // }
            }
            return b


        }
        return  []



    }

    const getHeaderHeight = () => {
    let totalWidth = 0;

    if (props.tableData && props.tableData[0]) {
        const columnCount = props.tableData[0].length;

        if (columnCount < 8) {
            // Distribute 100% width evenly across all columns
            const widthPerColumn = 80 / columnCount; // Each column gets equal width
            totalWidth = 80; // Total width is always 100% for < 8 columns
        } else {
            // Use existing logic for > 8 columns
            for (let i = 0; i < columnCount; i++) {
                const cell = props.tableData[0][i];
                totalWidth += cell.length < 8 ? 10 : cell.length * 1.5;
            }
        }
    }

        return totalWidth;
    };


    // const getHeaderHeight = ()=>{
    //
    //     let f = 0;
    //
    //     if (props.tableData){
    //         if (props.tableData[0]) {
    //             for (let i = 0; i < props.tableData[0].length; i++) {
    //                 // if (props.tableData[0][i].length > 0) {
    //                 if (props.tableData[0][i].length < 8) {
    //                     f = f + 10
    //                 } else {
    //                     f = f + props.tableData[0][i].length * 1.5
    //                 }
    //                 // }
    //
    //             }
    //         }
    //     }
    //     return f
    //
    // }
    const fetchNextPage = ()=>{
        if (props.tableData.length > 0 && pageScroll <= props.tableData.length){
            setPageScroll(pageScroll+5)
        }
    }
    const fetchSearchPage = ()=>{
        if (searchData.length > 0 && searchScroll <= searchData.length){
            setSearchScroll(searchScroll+5)
        }
    }

    const checkEmpty = (arr)=>{
        for (let i=1;i < arr.length;i++){
            if (arr[i].length > 0){
                return true
            }
        }
        return false

    }


    return (

        <Modal id={'view_csv_qbp'}

               visible={props.status} width={'80vw'}
               changeStatus={props.changeStatus}
               closable={false}
               destroyOnClose={true}
               style={{
                   height: 'auto', padding: 0, borderRadius: 0, top: '6.5vw',left:'10vw',margin:0,
                   display: 'flex', flexDirection: 'column',position:'absolute',zIndex:20,backgroundColor:'white',
               }} footer={null}
               bodyStyle={ant_body}>
            <div style={{width:'100%',display:'flex',flexDirection:'column'}}>
                <div style={{backgroundColor:'#F48C33',width:'100%',height:'6vw',justifyContent:'center',alignItems:'center',display:'flex'}}>
                    <div style={{fontFamily: 'verdana', fontSize:'1.8vw',color:'#FFFFFF',fontWeight:500,lineHeight:'2vw'}}>DATA AWARE PRINT LIST</div>
                    <img alt={'cancelIcon'} src={cancelIcon} style={{width:'0.65vw',height:'0.65vw',cursor:'pointer',
                        marginLeft:'77vw',marginTop:'0.15vw',position:'absolute'}} onClick={()=>{
                        props.changeStatus()
                    }}/>
                </div>

                <div  style={{display:'flex',flexDirection:'row',marginTop:'2vw',paddingLeft:'2vw'}}>
                    <div style={qty_text}>Set all quantity to</div>
                    <input style={{outline:'none',textAlign:'center', fontSize:'1vw', fontFamily:'verdana',color:'black',
                        width:'5vw',height: '1.61vw',backgroundColor:'#FFFFFF',marginLeft:'1vw',
                        display:'flex',flexDirection:'row',border:'0.06vw solid black',borderRadius:'1.5vw'}}
                           value={overallQTY}
                           onChange={(e)=>(isNumeric(e.target.value) || e.currentTarget.value === '')   ? setOverallQTY(e.target.value): null}  />
                    <div style={{...popup_btn_style,fontFamily: 'verdana',fontSize:'1vw',marginLeft:'2vw'}}
                         onClick={(e)=>{
                             if (overallQTY.toString().length > 0){
                                 if (filterSearch.length === 0){
                                     props.setQTYAll(parseInt(overallQTY).toString())
                                 } else {
                                     let jb = newArrDataLoop()

                                     let g = [...props.qtyData]

                                     for (let m =0;m < jb.length;m++){

                                        g[jb[m]-1] = parseInt(overallQTY).toString()
                                    }
                                     props.setQTYSelect(g)
                                 }
                             }}}>SET</div>

                    <div style={{...qty_text,marginLeft:'2vw'}}>Search for</div>
                    <input key={'filterSearchInput'}
                        onChange={(e)=>{
                            setSearchOn(true)
                            setFilterSearch(e.target.value)
                        }}
                        style={{outline:'none',textAlign:'left', fontSize:'1vw', fontFamily:'verdana',color:'black',
                        width:'12vw',height: '1.61vw',backgroundColor:'#FFFFFF',marginLeft:'1vw',paddingLeft:'0.7vw',
                        display:'flex',flexDirection:'row',border:'0.06vw solid black',borderRadius:'1.5vw'}}
                        defaultValue={filterSearch} />
                    <div key={'columns-margin'} style={{...qty_text,marginLeft:'2vw'}}>in Column</div>
                    <select value={selectedColumn} onChange={(e)=>{
                        setSelectedColumn(e.target.value)
                        if (filterSearch.length > 0 ){
                            setSearchOn(true)
                        }
                    }} id='selectTableData' style={{outline:'none',textAlign:'left', fontSize:'1vw', fontFamily:'verdana',color:'black',
                        width:'12vw',height: '2vw',backgroundColor:'#FFFFFF',marginLeft:'1vw',paddingLeft:'0.7vw',
                        display:'flex',flexDirection:'row',border:'0.06vw solid black',borderRadius:'1.5vw',padding:0}}>
                        {columnFirst}
                    </select>

                    <div onClick={()=>{
                        setFilterSearch('')
                        props.nextCSV()
                    }}
                         style={{...popup_btn_style,fontFamily: 'verdana',fontSize:'1vw',marginLeft:'2vw'}}>NEXT</div>

                </div>


            <div style={{display:'flex',flexDirection:'column',marginTop:'1.2vw',overflowX:'hidden',justifyContent:'space-around'}}>
                <div id={'colscroll'} style={{display:'flex',overflowX:'hidden',justifyContent:'space-around',flexDirection:'column',}}>
                {
                    props.tableData ?
                        filterSearch.length === 0 ?

                            props.tableData.map((item,index)=>{
                                if (index === 0){
                                return(
                                    <div key={'tableDataFilterNone'+index} style={{
                                        display:'flex',flexDirection:'row',
                                        paddingBottom:'0.5vw',
                                        paddingLeft:'1vw',paddingTop:'0.3vw',paddingRight:'1vw',
                                        marginTop:'0.625vw',backgroundColor:'#FACE4E',
                                        width: getHeaderHeight()+'vw',

                                    }}>
                                        {
                                            item.map((c,cIndex)=>{
                                                    return(
                                                        <div key={'tableDataFilterNoneItem'+cIndex} style={{display:'flex',marginTop:'0.5vw',justifyContent:'center',
                                                            width: c.length === 0 ? '10vw':c.length < 8 ? '10vw':c.length*1.5+'vw',backgroundColor:'#FACE4E',
                                                            }}>
                                                            {
                                                                cIndex === 0 ?
                                                                    index === 0 ?
                                                                        <div style={qty_text}>QTY</div>
                                                                        :
                                                                    null
                                                                    :
                                                                    <div style={{textAlign:'center',
                                                                        alignSelf:'center',fontFamily: 'verdana',color:'#545454',
                                                                        fontSize:'1vw'}}>{props.truncated ? c.length > 20 ? c.substring(0,20)+'...': c: c}</div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>

                                )}

                            }) : null : null

                }
                </div>

                <div onScroll={(e)=>filterSearch.toString().length ===  0 ?fetchNextPage(): fetchSearchPage()} style={{display:'flex',height:'30vw',overflowY:'scroll',width:'100%'}}>

                    <div style={{display:'flex',flexDirection:'column',height:'28vw',overflowX:'scroll',
                        overflowY:'scroll',position:'relative',width:getHeaderHeight()+'vw',
                    }}  onScroll={(e)=>{
                        let fg =  document.getElementById('colscroll')
                        fg.scrollLeft = e.currentTarget.scrollLeft
                    }}>
                {
                    props.tableData ?

                        filterSearch.length === 0 ?

                        props.tableData.map((item,index)=> {
                            if (index < pageScroll) {

                                if (index > 0 && index !== props.tableData.length - 1 && checkEmpty(item)) {

                                    return (

                                        <div key={'tableData'+index} onClick={() => {
                                            let gf = [...props.qtyData]
                                            if (gf[index - 1] === '0' || gf[index - 1] === '' && !document.activeElement.id.includes('inp_num_val')) {
                                                gf[index - 1] = '1'
                                                props.setQTYSelect(gf)
                                            }

                                        }} style={index % 2 === 0 ? {
                                                ...table_div,
                                                backgroundColor: '#EEEEEE',
                                                width: getHeaderHeight() + 'vw',
                                                paddingBottom: index !== props.tableData.length - 1 ? '2vw' : '1vw'

                                            }
                                            :
                                            {
                                                ...table_div,
                                                width: getHeaderHeight() + 'vw',
                                                paddingBottom: index !== props.tableData.length - 1 ? '2vw' : '1vw'
                                            }}>
                                            {
                                                item.map((c, cIndex) => {
                                                    return (
                                                        <div key={'tableDataItem'+cIndex} style={{
                                                            display: 'flex',
                                                            marginTop: '0.5vw',
                                                            justifyContent: 'center',
                                                            width: props.tableData[0][cIndex] ? props.tableData[0][cIndex].length === 0 ? '10vw' : props.tableData[0][cIndex].length < 8 ? '10vw' : props.tableData[0][cIndex].length * 1.5 + 'vw' : '10vw',
                                                        }}>
                                                            {
                                                                cIndex === 0 ?
                                                                    index === 0 ?
                                                                        <div style={qty_text}>QTY</div>
                                                                        :
                                                                        <input value={props.qtyData[index - 1]}
                                                                               onChange={(e) => {
                                                                                   let j = e.currentTarget.id;
                                                                                   let jx = Number(j.substring(11, j.length))
                                                                                   let g = [...props.qtyData]
                                                                                   if (isNumeric(e.currentTarget.value) || e.currentTarget.value === '0' || e.currentTarget.value === '') {
                                                                                       g[jx - 1] = e.currentTarget.value
                                                                                       props.setQTYSelect(g)

                                                                                   }

                                                                               }
                                                                               } id={'inp_num_val' + index} style={{
                                                                            textAlign: 'center',
                                                                            width: '5vw',
                                                                            height: '1.5vw',
                                                                            alignSelf: 'center',
                                                                            fontFamily: 'verdana',
                                                                            outline: 'none',
                                                                            borderRadius: '1.5vw',
                                                                            fontSize: '1vw'
                                                                        }}/>
                                                                    :
                                                                    <div
                                                                        style={index === 0 ? {
                                                                            textAlign: 'center',
                                                                            alignSelf: 'center',
                                                                            fontFamily: 'verdana',
                                                                            color: '#545454',
                                                                            fontSize: '1vw',
                                                                            overflowWrap: 'break-word',
                                                                            width:'90%'
                                                                        } : {
                                                                            textAlign: 'center',
                                                                            alignSelf: 'center', fontFamily: 'verdana',
                                                                            fontSize: '1vw',
                                                                            overflowWrap: 'break-word',
                                                                            width:'90%'
                                                                        }}>{props.truncated ? c.length > 20 ? c.substring(0,20)+'...': c: c}</div>
                                                            }

                                                        </div>
                                                    )

                                                })
                                            }
                                        </div>
                                    )
                                }
                            }
                        }):
                                    searchData.map((item, index) => {
                                        // if (index < searchScroll) {
                                            return (
                                                <div key={'searchData'+index}  style={index % 2 === 0 ?  index !== 0 ?{
                                                        ...table_div,
                                                        backgroundColor: '#EEEEEE',
                                                        width: getHeaderHeight() + 'vw',
                                                        paddingBottom: index !== searchData.length - 1 ? '2vw' : '1vw'

                                                    } : {
                                                        ...table_div,
                                                        backgroundColor:'#FACE4E',
                                                        width: getHeaderHeight() + 'vw'
                                                    }
                                                    :
                                                    {
                                                        ...table_div,
                                                        width: getHeaderHeight() + 'vw',
                                                        paddingBottom: index !== searchData.length - 1 ? '2vw' : '1vw'

                                                    }}>
                                                    {
                                                        item.map((c, cIndex) => {
                                                            return (
                                                                <div key={'searchDataItem'+cIndex} style={{
                                                                    display: 'flex',
                                                                    marginTop: '0.5vw',
                                                                    justifyContent: 'center',
                                                                    width: props.tableData[0][cIndex] ? props.tableData[0][cIndex].length === 0 ? '10vw' : props.tableData[0][cIndex].length < 8 ? '10vw' : props.tableData[0][cIndex].length * 1.5 + 'vw' : '10vw',
                                                                }} onClick={()=>{
                                                                  let gf = [...props.qtyData]
                                                                    if (gf[index - 1] === '0' || gf[index - 1] === '' && !document.activeElement.id.includes('inp_num_val')) {
                                                                        gf[index - 1] = '1'
                                                                        props.setQTYSelect(gf)
                                                                    }
                                                                }}>
                                                                    {
                                                                        cIndex === 0 ?
                                                                            index === 0 ?
                                                                                <div style={qty_text}>QTY</div>
                                                                                :
                                                                                <input value={props.qtyData[index - 1]}
                                                                                       onChange={(e) => {
                                                                                           let j = e.currentTarget.id;
                                                                                           let jx = Number(j.substring(11, j.length))
                                                                                           let g = [...props.qtyData]
                                                                                           g[jx - 1] = e.currentTarget.value
                                                                                           props.setQTYSelect(g)

                                                                                       }
                                                                                       } id={'inp_num_val' + index}
                                                                                       style={{
                                                                                           textAlign: 'center',
                                                                                           width: '40%',
                                                                                           alignSelf: 'center',
                                                                                           fontFamily: 'verdana',
                                                                                           outline: 'none',
                                                                                           borderRadius: '1.5vw',
                                                                                           fontSize: '1vw',
                                                                                       }}/>
                                                                            :


                                                                            <div style={index === 0 ? {
                                                                                textAlign: 'center',
                                                                                alignSelf: 'center',
                                                                                fontFamily: 'verdana',
                                                                                color: '#545454',
                                                                                fontSize: '1vw',
                                                                                overflowWrap: 'break-word',
                                                                                width: '90%'
                                                                            } : {
                                                                                textAlign: 'center',
                                                                                alignSelf: 'center',
                                                                                fontFamily: 'verdana',
                                                                                fontSize: '1vw',
                                                                                overflowWrap: 'break-word',
                                                                                width: '90%'
                                                                            }}>{props.truncated ? c.length > 20 ? c.substring(0,20)+'...': c: c}</div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        // }
                                    })
                        : null
                }
                    </div>
                </div>
            </div>
            </div>
        </Modal>
    )}
const ant_body= {
    width: '100%', height: '42.5vw',overflow:'hidden',
    borderRadius: 0, border: '0.2vw solid #FACE4E', maxWidth:'79.8vw',
    minWidth:'1vw',padding:0,margin:0
}
const table_div = {
    display:'flex',flexDirection:'row',width:'80vw',
    paddingBottom:'1vw',
    paddingLeft:'0.5vw',paddingTop:'1vw',marginTop:'0.4vw'
}
const popup_btn_style = {
    backgroundColor:'#56CFE1',color:'white',width:'7vw',height:'2vw',
    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
    fontFamily: 'verdana',fontSize:'1.2vw',borderRadius:'1.5vw'
}
const qty_text = {
    textAlign:'center',
    alignSelf:'center',fontFamily: 'verdana',color:'#545454',
    fontSize:'1vw'
}
export default ViewCSVQBP
