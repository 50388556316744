const language_Options = {
    'English':{'Toolbar':{copy:'Copy',paste:'Paste',delete:'Delete a field',zIn:'Zoom In',zOut:'Zoom Out',
            mD:'Move all fields down',mU:'Move all fields up',mL:'Move all fields left',mR:'Move all fields right',
            batch:'Create a new print batch',settings:'Settings',help:'Help',label:'LABEL',data:'DATA'},

        'drawLabel':{editFile:'Edit the file name',createL:'Create a new layout',open:'Open an existing layout',
            save:'Save the current layout',width:'Enter the page or roll width',height:'Enter the page height (sheets) or label height (rolls)',
            pagesz:'Create or edit a page size',apply:'APPLY'},

        'textLayer':{name:'Name for this text field',style:'Select a font style',size:'Select a font size',
            bold:'Bold text',italic:'Italic text',strike:'Strike through text',underline:'Underlined text',
            invert:'Invert text',transparent:'Transparent text',left:'Left align',center:'Center align',right:'Right align',
            multiline:'Multiline text',tColor:'Text color',bColor:'Background color',maxW:'Multiline maximum width',
            vLine:'Multiline vertical line space',pLeft:'Position from left',pTop:'Position from top',mR:'Move right',
            mL:'Move left',mU:'Move up',mD:'Move down',cclock:'Rotate counterclockwise',clock:'Rotate clockwise',
            degree:'Enter the degree of rotation',forward:'Bring forward',backward:'Send backward',lock:'If ticked the position is locked',lockT:'Lock',
            noPrint:'If ticked this field will not print',noPrintT:'No Print',newText:'CREATE NEW TEXT'},

        'barcodeLayer':{name:'Name for this barcode field',symbol:'Select the barcode symbology',dpi:'Select your printers resolution (dpi)',
            size:'Select a barcode size',height:'Select a barcode height',invert:'Invert barcode',left:'Left align',center:'Center align',
            right:'Right align',bcdColor:'Barcode color',bColor:'Background color',transparent:'Transparent',pLeft:'Position from left',pTop:'Position from top',
            mR:'Move right',mL:'Move left',mU:'Move up',mD:'Move down',cclock:'Rotate counterclockwise',clock:'Rotate clockwise',degree:'Enter the degree of rotation',
            forward:'Bring forward',backward:'Send backward',lock:'If ticked the position is locked',lockT:'Lock',noPrint:'If ticked this field will not print',noPrintT:'No Print',
            newBarcode:'CREATE NEW BARCODE'},

        'imageLayer':{name:'Name for this picture field',browse:'Browse for a picture file',width:'Select the width',height:'Select the height',
            aspect:'Lock the aspect ratio',stretch:'Allow to stretch',spL:'Shows the position from left',spT:'Shows the position from top',
            pLeft:'Position from left',pTop:'Position from top', mR:'Move right',mL:'Move left',mU:'Move up',
            mD:'Move down',cclock:'Rotate counterclockwise',clock:'Rotate clockwise',degree:'Enter the degree of rotation',
            forward:'Bring forward',backward:'Send backward',lock:'If ticked the position is locked',lockT:'Lock',
            noPrint:'If ticked the image will not print',noPrintT:'No Print',newImage:'ADD IMAGE'},

        'shapeLayer':{name:'Name for this shape field',width:'Select the width',height:'Select the height', aspect:'Lock the aspect ratio',stretch:'Allow to stretch',
            line:'Create a line',circle:'Create a circle or ellipse',square:'Create a square or rectangle',thick:'Select the line thickness',
            brColor:'Enter the border color',fillColor:'Enter the fill color',pLeft:'Position from left',pTop:'Position from top', mR:'Move right',
            mL:'Move left',mU:'Move up', mD:'Move down',cclock:'Rotate counterclockwise',clock:'Rotate clockwise',
            degree:'Enter the degree of rotation', forward:'Bring forward',backward:'Send backward',lock:'If ticked the position is locked',
            lockT:'Lock',noPrint:'If ticked the shape will not print',noPrintT:'No Print',newShape:'CREATE NEW SHAPE'},

        'leftTab':{label:'Create or edit a new label',text:'Add or edit a text field',barcode:'Add or edit a barcode field',
            image:'Add or edit an image field',shape:'Add or edit a shape',dataVar:'Select Variable Properties of a Field',
            excel:'Link a Field to a Text File or Excel Sheet'},

        'popup':{paste:'PASTE OPTIONS',uniquePaste:'Enter a unique name for the pasted field',cancel:'CANCEL',ok:'OK',
            stamp:'Date Stamp',stampFormat:'Choose a date stamp format',apply:'APPLY',error:'ERROR',uniqueError:'Field name must be unique',
            imageError:'Image Upload Error',saveChange:'Save Changes ?',saveDecide:'Do you want to save changes ?',yes:'YES',no:'NO',
            saveLabel:'Do you want to save current label ?',qPrint:'Question Before Print',qty:'Quantity',printPrev:'REPRINT PREVIOUS',
            addList:'ADD TO LIST',print:'PRINT',settings:'Settings',lang:'LANGUAGE',setTrans:'SET TRANSLATIONS',rotate:'Rotated Field',
            moveRotate:'To move or edit rotated text use the sidebar controls',saveAppendCSV:'Your csv file has been updated. Save the updated file?',
            saveResetCSV:'The new csv file has different columns. data links will be lost. Save the new file?',important: 'Important !',
            csvUpdate: 'File updated. Now link fields on your label to the new column data'},


    },


    'Spanish':{'Toolbar':{copy:'Copiar',paste:'Pegar',delete:'Eliminar un campo',zIn:'Acercarse',zOut:'Disminuir el zoom',
            mD:'Mover todos los campos hacia abajo',mU:'Mover todos los campos hacia arriba',
            mL:'Mover todos los campos a la izquierda',mR:'Mover todos los campos a la derecha',
            batch:'Crear un nuevo lote de impresión',settings:'Ajustes',help:'Ayuda',label:'ETIQUETA',data:'DATOS'},

        'drawLabel':{editFile:'Edite el nombre del archivo',createL:'Crea un nuevo diseño',open:'Abrir un diseño existente',
            save:'Guardar el diseño actual',width:'Ingrese la página o el ancho del rollo',height:'Ingrese la altura de la página (hojas) o la altura de la etiqueta (rollos)',
            pagesz:'Crear o editar un tamaño de página',apply:'APLICAR'},

        'textLayer':{name:'Nombre de este campo de texto',style:'Seleccione un estilo de fuente',size:'Seleccione un tamaño de fuente',
            bold:'Texto en negrita',italic:'Texto en cursiva',strike:'Tachar el texto',underline:'Texto subrayado',
            invert:'Invertir texto',transparent:'Texto transparente',left:'Alinear a la izquierda',center:'Alinear al centro',right:'Alinear a la derecha',
            multiline:'Texto de varias líneas',tColor:'Color de texto',bColor:'Color de fondo',maxW:'Ancho máximo multilínea',
            vLine:'Espacio de línea vertical multilínea',pLeft:'Posición desde la izquierda',pTop:'Posición desde arriba',mR:'Mover a la derecha',
            mL:'Mover hacia la izquierda',mU:'Ascender',mD:'Mover hacia abajo',cclock:'Girar en sentido antihorario',clock:'Rotar las agujas del reloj',
            degree:'Ingrese el grado de rotación',forward:'Adelantar',backward:'Enviar atrás',lock:'Si se marca, la posición está bloqueada',lockT:'Trabar',
            noPrint:'Si se marca este campo no se imprimirá',noPrintT:'Sin impresión',newText:'CREAR TEXTO NUEVO'},


        'barcodeLayer':{name:'Nombre de este campo de código de barras',symbol:'Seleccione la simbología del código de barras',dpi:'Seleccione la resolución de su impresora (ppp)',
            size:'Seleccione un tamaño de código de barras',height:'Seleccione una altura de código de barras',invert:'Invertir código de barras',left:'Alinear a la izquierda',center:'Alinear al centro',
            right:'Alinear a la derecha',bcdColor:'Color del código de barras',bColor:'Color de fondo',transparent:'Transparente',pLeft:'Posición desde la izquierda',pTop:'Posición desde arriba',
            mR:'Mover a la derecha',mL:'Mover hacia la izquierda',mU:'Ascender',mD:'Mover hacia abajo',cclock:'Girar en sentido antihorario',clock:'Rotar las agujas del reloj',degree:'Ingrese el grado de rotación',
            forward:'Adelantar',backward:'Enviar atrás',lock:'Si se marca, la posición está bloqueada',lockT:'Trabar',noPrint:'Si se marca este campo no se imprimirá',noPrintT:'Sin impresión',
            newBarcode:'CREAR NUEVO CÓDIGO DE BARRAS'},

        'imageLayer':{name:'Nombre para este campo de imagen',browse:'Buscar un archivo de imagen',width:'Seleccione el ancho',height:'Seleccione la altura',
            aspect:'Bloquear la relación de aspecto',stretch:'Dejar estirar',spL:'Muestra la posición desde la izquierda.',spT:'Muestra la posición desde arriba',
            pLeft:'Posición desde la izquierda',pTop:'Posición desde arriba', mR:'Mover a la derecha',mL:'Mover hacia la izquierda',
            mU:'Ascender',mD:'Mover hacia abajo',cclock:'Girar en sentido antihorario',clock:'Rotar las agujas del reloj',degree:'Ingrese el grado de rotación',
            forward:'Adelantar',backward:'Enviar atrás',lock:'Si se marca, la posición está bloqueada',lockT:'Trabar',
            noPrint:'Si se marca, la imagen no se imprimirá',noPrintT:'No Print',newImage:'AÑADIR IMAGEN'},

        'shapeLayer':{name:'Nombre para este campo de forma',width:'Seleccione el ancho',height:'Seleccione la altura', aspect:'Bloquear la relación de aspecto',stretch:'Dejar estirar',
            line:'Crea una linea',circle:'Crea un círculo o elipse',square:'Crea un cuadrado o un rectángulo',thick:'Seleccione el grosor de la línea',
            brColor:'Ingrese el color del borde',fillColor:'Ingrese el color de relleno', pLeft:'Posición desde la izquierda',
            pTop:'Posición desde arriba', mR:'Mover a la derecha',mL:'Mover hacia la izquierda',
            mU:'Ascender',mD:'Mover hacia abajo',cclock:'Girar en sentido antihorario',clock:'Rotar las agujas del reloj',degree:'Ingrese el grado de rotación',
            forward:'Adelantar',backward:'Enviar atrás',lock:'Si se marca, la posición está bloqueada',lockT:'Trabar',
            noPrint:'Si se marca, la forma no se imprimirá',noPrintT:'Sin impresión',newShape:'CREAR NUEVA FORMA'},

        'leftTab':{label:'Crea o edita una nueva etiqueta',text:'Agregar o editar un campo de texto',barcode:'Agregar o editar un campo de código de barras',
            image:'Agregar o editar un campo de imagen',shape:'Agregar o editar una forma',dataVar:'Seleccionar propiedades de variable de un campo',
            excel:'Vincular un campo a un archivo de texto o una hoja de Excel'},

        'popup':{paste:'OPCIONES DE PEGADO',uniquePaste:'Ingrese un nombre único para el campo pegado',cancel:'CANCELAR',ok:'OK',
            stamp:'Sello de la fecha',stampFormat:'Elija un formato de sello de fecha',apply:'SOLICITAR',error:'ERROR',uniqueError:'El nombre del campo debe ser único',
            imageError:'Error de carga de imagen',saveChange:'Guardar cambios ?',saveDecide:'Quieres guardar los cambios ?',yes:'sí',no:'NO',
            saveLabel:'¿Quieres guardar la etiqueta actual?',qPrint:'Pregunta antes de imprimir',qty:'Cantidad',printPrev:'REIMPRIMIR ANTERIOR',
            addList:'AGREGAR A LA LISTA',print:'IMPRESIÓN',settings:'Settings',lang:'IDIOMA',setTrans:'CONFIGURAR TRADUCCIONES',rotate:'Campo girado',
            moveRotate:'Para mover o editar texto girado, use los controles de la barra lateral',saveAppendCSV:'Tu archivo csv ha sido actualizado. ¿Quieres guardar el archivo actualizado?',
            saveResetCSV:'El nuevo archivo csv tiene columnas diferentes. Se perderán los vínculos de datos. ¿Quieres guardar el nuevo archivo?',important: 'Importante !',
            csvUpdate: 'Archivo actualizado. Ahora vincula los campos de tu etiqueta a los datos de la nueva columna'},

    },





    'French':{'Toolbar':{copy:'Copie',paste:'Pâte',delete:'Supprimer un champ',zIn:'Agrandir',zOut:'Dézoomer',
            mD:'Déplacer tous les champs vers le bas',mU:'Déplacer tous les champs vers le haut',mL:'Déplacer tous les champs vers la gauche',
            mR:'Déplacer tous les champs vers la droite', batch:'Créer un nouveau lot d\'impression',settings:'Réglages',help:'Aider',
            label:'ÉTIQUETER',data:'DATA'},

        'drawLabel':{editFile:'Modifier le nom du fichier',createL:'Créer une nouvelle mise en page',open:'Ouvrir une mise en page existante',
            save:'Enregistrer la mise en page actuelle',width:'Entrez la page ou la largeur du rouleau',height:'Entrez la hauteur de page (feuilles) ou la hauteur d\'étiquette (rouleaux)',
            pagesz:'Créer ou modifier une taille de page',apply:'APPLIQUER'},

        'textLayer':{name:'Nom de ce champ de texte',style:'Sélectionnez un style de police',size:'Sélectionnez une taille de police',
            bold:'Texte en gras',italic:'Texte en italique',strike:'Biffer le texte',underline:'Texte souligné',
            invert:'Inverser le texte',transparent:'Texte transparent',left:'Aligner à gauche',center:'Aligner au centre',right:'Aligner à droite',
            multiline:'Texte multiligne',tColor:'Couleur du texte',bColor:'Couleur de l\'arrière plan',maxW:'Largeur maximale multiligne',
            vLine:'Espace de ligne verticale multiligne',pLeft:'Position de gauche',pTop:'Position du haut',mR:'Déplacer vers la droite',
            mL:'Se déplacer à gauche',mU:'Déplacer vers le haut',mD:'Descendre',cclock:'Tourner dans le sens antihoraire',clock:'Le sens des aiguilles d\'une montre',
            degree:'Entrez le degré de rotation',forward:'Avancer',backward:'Envoyer vers l\'arrière',lock:'Si coché, la position est verrouillée',lockT:'Serrure',
            noPrint:'Si coché, ce champ ne s\'imprimera pas',noPrintT:'Pas d\'impression',newText:'CRÉER UN NOUVEAU TEXTE'},

        'barcodeLayer':{name:'Nom de ce champ de code-barres',symbol:'Sélectionnez la symbologie du code-barres',dpi:'Sélectionnez la résolution de vos imprimantes (dpi)',
            size:'Sélectionnez une taille de code-barres',height:'Sélectionnez une hauteur de code-barres',invert:'Inverser le code-barres',left:'Aligner à gauche',center:'Aligner au centre',
            right:'Aligner à droite',bcdColor:'Couleur du code-barres',bColor:'Couleur de l\'arrière plan',transparent:'Transparent',pLeft:'Position de gauche',pTop:'Position du haut',
            mR:'Déplacer vers la droite',mL:'Se déplacer à gauche',mU:'Déplacer vers le haut',mD:'Descendre',cclock:'Tourner dans le sens antihoraire',clock:'Le sens des aiguilles d\'une montre',degree:'Entrez le degré de rotation',
            forward:'Avancer',backward:'Envoyer vers l\'arrière',lock:'Si coché, la position est verrouillée',lockT:'Serrure',noPrint:'Si coché, ce champ ne s\'imprimera pas',noPrintT:'Pas d\'impression',
            newBarcode:'CRÉER UN NOUVEAU CODE À BARRES'},

        'imageLayer':{name:'Nom de ce champ d\'image',browse:'Rechercher un fichier image',width:'Sélectionnez la largeur',height:'Sélectionnez la hauteur',
            aspect:'Verrouiller le rapport hauteur / largeur',stretch:'Laisser s\'étirer',spL:'Affiche la position de gauche',spT:'Affiche la position du haut',
            pLeft:'Position de gauche',pTop:'Position du haut', mR:'Déplacer vers la droite',mL:'Se déplacer à gauche',mU:'Déplacer vers le haut',
            mD:'Descendre',cclock:'Tourner dans le sens antihoraire',clock:'Le sens des aiguilles d\'une montre',degree:'Entrez le degré de rotation',
            forward:'Avancer',backward:'Envoyer vers l\'arrière',lock:'Si coché, la position est verrouillée',lockT:'Serrure',
            noPrint:'Si coché, l\'image ne s\'imprimera pas',noPrintT:'Pas d\'impression',newImage:'AJOUTER UNE IMAGE'},

        'shapeLayer':{name:'Nom de ce champ de forme', width:'Sélectionnez la largeur',height:'Sélectionnez la hauteur',
            aspect:'Verrouiller le rapport hauteur / largeur',stretch:'Laisser s\'étirer', line:'Crea una linea',circle:'Crea un círculo o elipse',square:'Crea un cuadrado o un rectángulo',thick:'Seleccione el grosor de la línea',
            brColor:'Ingrese el color del borde',fillColor:'Ingrese el color de rellenos',pLeft:'Position de gauche',pTop:'Position du haut', mR:'Déplacer vers la droite',mL:'Se déplacer à gauche',mU:'Déplacer vers le haut',
            mD:'Descendre',cclock:'Tourner dans le sens antihoraire',clock:'Le sens des aiguilles d\'une montre',degree:'Entrez le degré de rotation',
            forward:'Avancer',backward:'Envoyer vers l\'arrière',lock:'Si coché, la position est verrouillée',lockT:'Serrure',
            noPrint:'Si coché, la forme ne s\'imprimera pas',noPrintT:'Pas d\'impression',newShape:'CRÉER UNE NOUVELLE FORME'},

        'leftTab':{label:'Créer ou modifier une nouvelle étiquette',text:'Ajouter ou modifier un champ de texte',barcode:'Ajouter ou modifier un champ de code-barres',
            image:'Ajouter ou modifier un champ d\'image',shape:'Ajouter ou modifier une forme',dataVar:'Sélectionnez les propriétés variables d\'un champ',
            excel:'Lier un champ à un fichier texte ou une feuille Excel'},

        'popup':{paste:'OPTIONS DE COLLAGE',uniquePaste:'Entrez un nom unique pour le champ collé',cancel:'ANNULER',ok:'d\'accord',
            stamp:'Timbre de date',stampFormat:'Choisissez un format d\'horodatage',apply:'APPLIQUER',error:'ERREUR',uniqueError:'Le nom du champ doit être unique',
            imageError:'Erreur de téléchargement d\'image',saveChange:'Sauvegarder les modifications ?',saveDecide:'Voulez-vous enregistrer les modifications?',yes:'Oui',no:'Pas',
            saveLabel:'Voulez-vous enregistrer l\'étiquette actuelle?',qPrint:'Question avant impression',qty:'Quantité',printPrev:'RÉIMPRIMER PRÉCÉDENT',
            addList:'AJOUTER À LA LISTE',print:'IMPRIMER',settings:'Paramètres',lang:'LANGUE',setTrans:'DÉFINIR LES TRADUCTIONS',rotate:'Champ tourné',
            moveRotate:'Pour déplacer ou modifier le texte pivoté, utilisez les commandes de la barre latérale',saveAppendCSV:'Votre fichier csv a été mis à jour. Enregistrer le fichier mis à jour ?',
            saveResetCSV:'Le nouveau fichier csv contient des colonnes différentes. Les liens de données seront perdus. Enregistrer le nouveau fichier?',important: 'Important !',csvUpdate: 'Fichier mis à jour. Liez maintenant les champs de votre étiquette aux nouvelles données de colonne'},
    },




    'German':{'Toolbar':{copy:'Kopieren',paste:'Einfügen',delete:'Löschen Sie ein Feld',zIn:'Hineinzoomen',zOut:'Rauszoomen',
            mD:'Verschieben Sie alle Felder nach unten',mU:'Verschieben Sie alle Felder nach oben',mL:'Verschieben Sie alle Felder nach links',
            mR:'Verschieben Sie alle Felder nach rechts', batch:'Erstellen Sie einen neuen Druckstapel',settings:'die Einstellungen',
            help:'Hilfe',label:'ETIKETTE',data:'DATEN'},

        'drawLabel':{editFile:'Bearbeiten Sie den Dateinamen',createL:'Erstellen Sie ein neues Layout',open:'Öffnen Sie ein vorhandenes Layout',
            save:'Speichern Sie das aktuelle Layout',width:'Geben Sie die Seiten- oder Rollenbreite ein',height:'Geben Sie die Seitenhöhe (Blätter) oder die Etikettenhöhe (Rollen) ein.',
            pagesz:'Erstellen oder bearbeiten Sie eine Seitengröße',apply:'ANWENDEN'},

        'textLayer':{name:'Name für dieses Textfeld',style:'Wählen Sie einen Schriftstil',size:'Wählen Sie eine Schriftgröße',
            bold:'Fettgedruckter Text',italic:'Kursiver Text',strike:'Durchgestrichener Text',underline:'Unterstrichener Text',
            invert:'Text umkehren',transparent:'Transparenter Text',left:'Links ausrichten',center:'Mitte ausrichten',right:'Rechts ausrichten',
            multiline:'Mehrzeiliger Text',tColor:'Textfarbe',bColor:'Hintergrundfarbe',maxW:'Mehrzeilige maximale Breite',
            vLine:'Mehrzeiliger vertikaler Linienraum',pLeft:'Position von links',pTop:'Position von oben',mR:'Nach rechts bewegen',
            mL:'Geh nach links',mU:'Bewegen Sie sich nach oben',mD:'Sich abwärts bewegen',cclock:'Gegen den Uhrzeigersinn drehen',clock:'Im Uhrzeiger sinn drehen',
            degree:'Geben Sie den Rotationsgrad ein',forward:'Vorwärts bringen',backward:'Rückwärts senden',lock:'Wenn angekreuzt, ist die Position gesperrt',lockT:'Sperren',
            noPrint:'Wenn dieses Kontrollkästchen aktiviert ist, wird dieses Feld nicht gedruckt',noPrintT:'Kein Druck',newText:'NEUEN TEXT ERSTELLEN'},

        'barcodeLayer':{name:'Name für dieses Barcodefeld',symbol:'Wählen Sie die Barcode-Symbologie aus',dpi:'Wählen Sie die Auflösung Ihres Druckers (dpi).',
            size:'Wählen Sie eine Barcode-Größe',height:'Wählen Sie eine Barcode-Höhe',invert:'Barcode umkehren',left:'Links ausrichten',center:'Mitte ausrichten',
            right:'Rechts ausrichten',bcdColor:'Barcode-Farbe',bColor:'Hintergrundfarbe',transparent:'Transparent',pLeft:'Position von links',pTop:'Position von oben',
            mR:'Nach rechts bewegen',mL:'Geh nach links',mU:'Bewegen Sie sich nach oben',mD:'Sich abwärts bewegen',cclock:'Gegen den Uhrzeigersinn drehen',clock:'Im Uhrzeiger sinn drehen',
            degree:'Geben Sie den Rotationsgrad ein', forward:'Vorwärts bringen',backward:'Rückwärts senden',lock:'Wenn angekreuzt, ist die Position gesperrt',lockT:'Sperren',
            noPrint:'Wenn dieses Kontrollkästchen aktiviert ist, wird dieses Feld nicht gedruckt',
            noPrintT:'Kein Druck', newBarcode:'NEUEN BARCODE ERSTELLEN'},

        'imageLayer':{name:'Name für dieses Bildfeld',browse:'Suchen Sie nach einer Bilddatei',width:'Wählen Sie die Breite',height:'Wählen Sie die Höhe',
            aspect:'Sperren Sie das Seitenverhältnis',stretch:'Dehnen lassen',spL:'Zeigt die Position von links an',spT:'Zeigt die Position von oben an',
            pLeft:'Position von links',pTop:'Position von oben', mR:'Nach rechts bewegen',mL:'Geh nach links',mU:'Bewegen Sie sich nach oben',
            mD:'Sich abwärts bewegen',cclock:'Gegen den Uhrzeigersinn drehen',clock:'Im Uhrzeiger sinn drehen', degree:'Geben Sie den Rotationsgrad ein',
            forward:'Vorwärts bringen',backward:'Rückwärts senden',lock:'Wenn angekreuzt, ist die Position gesperrt',lockT:'Sperren',
            noPrint:'Wenn dieses Kontrollkästchen aktiviert ist, wird das Bild nicht gedruckt',noPrintT:'Kein Druck',newImage:'BILD HINZUFÜGEN'},

        'shapeLayer':{name:'Name für dieses Formfeld', width:'Wählen Sie die Breite',height:'Wählen Sie die Höhe', aspect:'Sperren Sie das Seitenverhältnis',
            stretch:'Dehnen lassen', line:'Erstellen Sie eine Linie',circle:'Erstellen Sie einen Kreis oder eine Ellipse',
            square:'Erstellen Sie ein Quadrat oder Rechteck',thick:'Wählen Sie die Linienstärke', brColor:'Geben Sie die Rahmenfarbe ein',
            fillColor:'Geben Sie die Füllfarbe ein',pLeft:'Position von links',pTop:'Position von oben', mR:'Nach rechts bewegen',mL:'Geh nach links',mU:'Bewegen Sie sich nach oben',
            mD:'Sich abwärts bewegen',cclock:'Gegen den Uhrzeigersinn drehen',clock:'Im Uhrzeiger sinn drehen', degree:'Geben Sie den Rotationsgrad ein',
            forward:'Vorwärts bringen',backward:'Rückwärts senden',lock:'Wenn angekreuzt, ist die Position gesperrt',lockT:'Sperren',
            noPrint:'Wenn diese Option aktiviert ist, wird die Form nicht gedruckt',noPrintT:'Kein Druck',newShape:'NEUE FORM ERSTELLEN'},

        'leftTab':{label:'Erstellen oder bearbeiten Sie ein neues Etikett',text:'Fügen Sie ein Textfeld hinzu oder bearbeiten Sie es',barcode:'Fügen Sie ein Barcode-Feld hinzu oder bearbeiten Sie es',
            image:'Fügen Sie ein Bildfeld hinzu oder bearbeiten Sie es',shape:'Form hinzufügen oder bearbeiten',dataVar:'Wählen Sie die Variableneigenschaften eines Feldes aus',
            excel:'Verknüpfen Sie ein Feld mit einer Textdatei oder einer Excel-Tabelle'},

        'popup':{paste:'PASTE-OPTIONEN',uniquePaste:'Geben Sie einen eindeutigen Namen für das eingefügte Feld ein',cancel:'STORNIEREN',ok:'OK',
            stamp:'Datumsstempel',stampFormat:'Wählen Sie ein Datumsstempelformat',apply:'ANWENDEN',error:'ERROR',uniqueError:'Der Feldname muss eindeutig sein',
            imageError:'Bild-Upload-Fehler',saveChange:'Änderungen speichern ?',saveDecide:'Möchten Sie Änderungen speichern?',yes:'Ja',no:'Nein',
            saveLabel:'Möchten Sie das aktuelle Etikett speichern?',qPrint:'Frage vor dem Drucken',qty:'Menge',printPrev:'VORHERIGER DRUCK',
            addList:'ZUR LISTE HINZUFÜGEN',print:'DRUCKEN',settings:'die Einstellungen',lang:'SPRACHE',setTrans:'ÜBERSETZUNGEN EINSTELLEN',rotate:'Gedrehtes Feld',
            moveRotate:'Verwenden Sie zum Verschieben oder Bearbeiten von gedrehtem Text die Steuerelemente der Seitenleiste',saveAppendCSV:'Ihre CSV-Datei wurde aktualisiert. Die aktualisierte Datei speichern?',
            saveResetCSV:'Die neue CSV-Datei hat andere Spalten. Datenlinks gehen verloren. Neue Datei speichern?',important: 'Wichtig !',
            csvUpdate: 'Datei aktualisiert. Verknüpfen Sie nun die Felder auf Ihrem Etikett mit den neuen Spaltendaten'},
    },


    'Chinese':{'Toolbar':{copy:'复制',paste:'粘贴',delete:'删除',zIn:'缩小',zOut:'放大',
            mD:'向下移动',mU:'向上移动',mL:'向左移动',mR:'向右移动', batch:'创建新的打印组',settings:'设置',help:'帮助',label:'标签',data:'数据'},

        'drawLabel':{editFile:'编辑文件名',createL:'创建布局',open:'打开已存布局',
            save:'保存布局',width:'输入页面或卷筒宽度',height:'输入页面高度（纸张）或标签高度（卷）',
            pagesz:'创建或编辑页面大小',apply:'申请'},

        'textLayer':{name:'文本名称',style:'选择字形',size:'选择字体大小',
            bold:'粗体文字',italic:'斜体文字',strike:'删除线文字',underline:'带下划线的文字',
            invert:'反转文字',transparent:'透明文字',left:'左对齐',center:'居中对齐',right:'右对齐',
            multiline:'多行文字',tColor:'文字颜色',bColor:'背景颜色',maxW:'多行最大宽度',
            vLine:'多行垂直行空间',pLeft:'从左到右的位置',pTop:'从上到下的位置',mR:'向右移动',
            mL:'向左移动',mU:'向上移动',mD:'向下移动',cclock:'逆时针旋转',clock:'顺时针旋转',
            degree:'输入旋转度',forward:'向前进',backward:'向后发送',lock:'如果勾选，则位置被锁定',lockT:'锁',
            noPrint:'如果勾选，则不会打印此字段',noPrintT:'没有列印',newText:'创建新文本'},

        'barcodeLayer':{name:'命名条形码名称',symbol:'选择条形码符号',dpi:'选择您的打印机分辨率（dpi）',
            size:'选择条形码尺寸',height:'选择条形码高度',invert:'反转条码',left:'左对齐',center:'居中对齐',
            right:'右对齐',bcdColor:'条码颜色',bColor:'背景颜色',transparent:'透明',pLeft:'从左到右的位置',pTop:'从上到下的位置',
            mR:'向右移',mL:'向左移',mU:'向上移',mD:'向下移',cclock:'逆时针旋转',clock:'顺时针旋转',degree:'输入旋转度',
            forward:'向前进',backward:'向后发送',lock:'如果勾选，则位置被锁定',lockT:'锁',noPrint:'如果勾选，则不会打印此字段',noPrintT:'没有列印',
            newBarcode:'创建新条形码'},

        'imageLayer':{name:'命名图片名称',browse:'浏览图片文件',width:'选择宽度',height:'选择高度',
            aspect:'锁定宽高比',stretch:'允许伸展',spL:'从左显示位置',spT:'从顶部显示位置',
            pLeft:'从左到右的位置',pTop:'从上到下的位置', mR:'向右移',mL:'向左移',mU:'向上移',mD:'向下移',cclock:'逆时针旋转',
            clock:'顺时针旋转',degree:'输入旋转度', forward:'向前进',backward:'向后发送',lock:'如果勾选，则位置被锁定',lockT:'锁',
            noPrint:'如果打勾，图像将不会打印',noPrintT:'没有列印',newImage:'添加图片'},

        'shapeLayer':{name:'命名此形状名称', width:'选择宽度',height:'选择高度', aspect:'锁定宽高比',stretch:'允许伸展',
            line:'创建一条线',circle:'创建一个圆或椭圆',square:'创建一个正方形或矩形',thick:'选择线条粗细',
            brColor:'输入边框颜色',fillColor:'输入填充颜色', pLeft:'从左到右的位置',pTop:'从上到下的位置', mR:'向右移',
            mL:'向左移',mU:'向上移',mD:'向下移',cclock:'逆时针旋转', clock:'顺时针旋转',degree:'输入旋转度', forward:'向前进',
            backward:'向后发送',lock:'如果勾选，则位置被锁定',lockT:'锁',
            noPrint:'如果打勾，形状将不会打印',noPrintT:'没有列印',newShape:'创建新形状'},

        'leftTab':{label:'创建或编辑新标签',text:'添加或编辑文本字段',barcode:'添加或编辑条形码字段',
            image:'添加或编辑图像字段',shape:'添加或编辑形状',dataVar:'选择字段的变量属性',
            excel:'将字段链接到文本文件'},

        'popup':{paste:'粘贴选项',uniquePaste:'输入粘贴字段专有名',cancel:'取消',ok:'确认',
            stamp:'创建日期',stampFormat:'选择日期格式',apply:'申请',error:'错误',uniqueError:'字段专有名',
            imageError:'图片上传错误',saveChange:'保存更改 ？',saveDecide:'保存更改吗？',yes:'确认',no:'取消',
            saveLabel:'要保存当前标签吗？',qPrint:'打印前的问题',qty:'数量',printPrev:'重印上一个',
            addList:'添加到列表',print:'打印',settings:'设置',lang:'语言',setTrans:'设置翻译',rotate:'旋转',
            moveRotate:'要移动或编辑旋转文本，请使用侧边栏控件',saveAppendCSV:'你的CSV文件已经更新了，你有储存更新后的文件了么？',
            saveResetCSV:'更新的文件有一个不同的栏， 储存的信息会丢失的。请储存新的文件。',important: '非常重要 !',
            csvUpdate: '文件已更新。现在将标签上的字段链接到新的列数据'},
    },


    'Portuguese':{'Toolbar':{copy:'Cópia de',paste:'Colar',delete:'Excluir um campo',zIn:'Mais Zoom',zOut:'Reduzir o zoom',
            mD:'Mova todos os campos para baixo',mU:'Mova todos os campos para cima',mL:'Mover todos os campos para a esquerda',
            mR:'Mover todos os campos para a direita', batch:'Crie um novo lote de impressão',settings:'Configurações',
            help:'Ajuda',label:'RÓTULO',data:'DADAS'},

        'drawLabel':{editFile:'Edite o nome do arquivo',createL:'Crie um novo layout',open:'Abra um layout existente',
            save:'Salve o layout atual',width:'Insira a página ou a largura do rolo',height:'Insira a altura da página (folhas) ou altura da etiqueta (rolos)',
            pagesz:'Crie ou edite um tamanho de página',apply:'APLIQUE'},

        'textLayer':{name:'Nome para este campo de texto',style:'Selecione um estilo de fonte',size:'Selecione um tamanho de fonte',
            bold:'Texto em negrito',italic:'Texto em itálico',strike:'Texto tachado',underline:'Texto sublinhado',
            invert:'Inverter texto',transparent:'Texto transparente',left:'Alinhamento à esquerda',center:'Alinhamento central',right:'Alinhar à direita',
            multiline:'Texto multilinha',tColor:'Cor do texto',bColor:'Cor de fundo',maxW:'Largura máxima multilinha',
            vLine:'Espaço de linha vertical multilinha',pLeft:'Posição da esquerda',pTop:'Posição de cima',mR:'Mover para a direita',
            mL:'Mova à esquerda',mU:'Subir',mD:'Mova para baixo',cclock:'Girar no sentido anti-horário',clock:'Rode no sentido dos ponteiros do relógio',
            degree:'Insira o grau de rotação',forward:'Apresentar',backward:'Enviar para trás',lock:'Se marcada, a posição está bloqueada',lockT:'Trancar',
            noPrint:'Se marcado, este campo não será impresso',noPrintT:'Sem impressão',newText:'CRIAR NOVO TEXTO'},

        'barcodeLayer':{name:'Nome para este campo de código de barras',symbol:'Selecione a simbologia do código de barras',dpi:'Selecione a resolução da sua impressora (dpi)',
            size:'Selecione um tamanho de código de barras',height:'Selecione a altura do código de barras',invert:'Inverter código de barras',left:'Alinhamento à esquerda',center:'Alinhamento central',
            right:'Alinhar à direita',bcdColor:'Cor do código de barras',bColor:'Cor de fundo',transparent:'Transparente',pLeft:'Posição da esquerda',pTop:'Posição de cima',
            mR:'Mover para a direita',mL:'Mova à esquerda',mU:'Subir',mD:'Mova para baixo',cclock:'Girar no sentido anti-horário',clock:'Rode no sentido dos ponteiros do relógio',degree:'Insira o grau de rotação',
            forward:'Apresentar',backward:'Enviar para trás',lock:'Se marcada, a posição está bloqueada',lockT:'Trancar',noPrint:'Se marcado, este campo não será impresso',noPrintT:'Sem impressão',
            newBarcode:'CRIAR NOVO CÓDIGO DE BARRAS'},

        'imageLayer':{name:'Nome para este campo de imagem',browse:'Procure um arquivo de imagem',width:'Selecione a largura',height:'Selecione a altura',
            aspect:'Bloqueie a proporção do aspecto',stretch:'Permitir esticar',spL:'Mostra a posição da esquerda',spT:'Mostra a posição de cima',
            pLeft:'Posição da esquerda',pTop:'Posição de cima', mR:'Mover para a direita',mL:'Mova à esquerda',mU:'Subir',
            mD:'Mova para baixo',cclock:'Girar no sentido anti-horário',clock:'Rode no sentido dos ponteiros do relógio',degree:'Insira o grau de rotação',
            forward:'Apresentar',backward:'Enviar para trás',lock:'Se marcada, a posição está bloqueada',lockT:'Trancar',
            noPrint:'Se marcada, a imagem não será impressa',noPrintT:'Sem impressão',newImage:'ADICIONAR IMAGEM'},

        'shapeLayer':{name:'Nome para este campo de forma',
            width:'Selecione a largura',height:'Selecione a altura', aspect:'Bloqueie a proporção do aspecto',stretch:'Permitir esticar',
            line:'Crie uma linha',circle:'Crie um círculo ou elipse',square:'Crie um quadrado ou retângulo',thick:'Selecione a espessura da linha',
            brColor:'Insira a cor da borda',fillColor:'Insira a cor de preenchimento', pLeft:'Posição da esquerda',pTop:'Posição de cima',
            mR:'Mover para a direita',mL:'Mova à esquerda',mU:'Subir', mD:'Mova para baixo',cclock:'Girar no sentido anti-horário',clock:'Rode no sentido dos ponteiros do relógio',
            degree:'Insira o grau de rotação', forward:'Apresentar',backward:'Enviar para trás',lock:'Se marcada, a posição está bloqueada',lockT:'Trancar',
            noPrint:'Se marcada, a forma não será impressa',noPrintT:'Sem impressão',newShape:'CRIAR NOVA FORMA'},

        'leftTab':{label:'Crie ou edite um novo rótulo',text:'Adicionar ou editar um campo de texto',barcode:'Adicionar ou editar um campo de código de barras',
            image:'Adicionar ou editar um campo de imagem',shape:'Adicionar ou editar uma forma',dataVar:'Selecione as propriedades variáveis de um campo',
            excel:'Vincular um campo a um arquivo de texto ou planilha do Excel'},

        'popup':{paste:'OPÇÕES DE PASTA',uniquePaste:'Insira um nome exclusivo para o campo colado',cancel:'CANCELAR',ok:'OK',
            stamp:'Carimbo de data',stampFormat:'Escolha um formato de carimbo de data',apply:'APLIQUE',error:'ERRO',uniqueError:'O nome do campo deve ser único',
            imageError:'Erro de upload de imagem',saveChange:'Salvar alterações?',saveDecide:'Você quer salvar as alterações ?',yes:'sim',no:'Não',
            saveLabel:'Quer salvar o rótulo atual?',qPrint:'Pergunta antes da impressão',qty:'Quantidade',printPrev:'REIMPRIMIR ANTERIOR',
            addList:'ADICIONADO A LISTA',print:'IMPRESSÃO',settings:'Definições',lang:'LÍNGUA',setTrans:'DEFINIR TRADUÇÕES',rotate:'Campo Rodado',
            moveRotate:'Para mover ou editar o texto girado, use os controles da barra lateral',saveAppendCSV:'O seu arquivo csv foi atualizado. Guardar o ficheiro atualizado?',
            saveResetCSV:'O novo ficheiro csv tem colunas diferentes. ligações de dados serão perdidas. Guardar o novo ficheiro?',important: 'Importante !',
            csvUpdate: 'Ficheiro atualizado. Agora ligue os campos do seu rótulo aos dados da nova coluna'},
    },


    'Russian':{'Toolbar':{copy:'Копировать',paste:'Вставить',delete:'Удалить поле',zIn:'Приблизить',zOut:'Уменьшить',
            mD:'Переместить все поля вниз',mU:'Переместить все поля вверх',mL:'Сдвинуть все поля влево',mR:'Переместить все поля вправо',
            batch:'Создать новый пакет печати',settings:'Настройки',help:'Помощь',label:'МЕТКА',data:'ДАННЫЕ'},

        'drawLabel':{editFile:'Отредактируйте имя файла',createL:'Создать новый макет',open:'Открыть существующий макет',
            save:'Сохранить текущий макет',width:'Введите ширину страницы или рулона',height:'Введите высоту страницы (листы) или высоту этикетки (рулоны)',
            pagesz:'Создать или отредактировать размер страницы',apply:'ПРИМЕНЯТЬ'},

        'textLayer':{name:'Имя для этого текстового поля',style:'Выберите стиль шрифта',size:'Выберите размер шрифта',
            bold:'Жирный текст',italic:'Курсивный текст',strike:'Зачеркнутый текст',underline:'Подчеркнутый текст',
            invert:'Инвертировать текст',transparent:'Прозрачный текст',left:'Выровнять по левому краю',center:'Выровнять по центру',right:'Выровнять по правому краю',
            multiline:'Многострочный текст',tColor:'Цвет текста',bColor:'Фоновый цвет',maxW:'Максимальная ширина многострочного',
            vLine:'Многострочный вертикальный интервал',pLeft:'Позиция слева',pTop:'Позиция сверху',mR:'Двигаться вправо',
            mL:'Двигай влево',mU:'Двигаться вверх',mD:'Двигаться вниз',cclock:'Вращаться против часовой стрелки',clock:'Повернуть по часовой стрелке',
            degree:'Введите степень вращения',forward:'Принести вперед',backward:'Отправить назад',lock:'Если отмечено галочкой, позиция заблокирована',lockT:'Замок',
            noPrint:'Если этот флажок установлен, это поле не будет печататься',noPrintT:'Нет печати',newText:'СОЗДАТЬ НОВЫЙ ТЕКСТ'},

        'barcodeLayer':{name:'Имя для этого поля штрих-кода',symbol:'Выберите символику штрих-кода',dpi:'Выберите разрешение вашего принтера (dpi)',
            size:'Выберите размер штрих-кода',height:'Выберите высоту штрих-кода',invert:'Инвертировать штрих-код',left:'Выровнять по левому краю',center:'Выровнять по центру',
            right:'Выровнять по правому краю',bcdColor:'Цвет штрих-кода',bColor:'Фоновый цвет',transparent:'Прозрачный',pLeft:'Позиция слева',pTop:'Позиция сверху',
            mR:'Двигаться вправо',mL:'Двигай влево',mU:'Двигаться вверх',mD:'Двигаться вниз',cclock:'Вращаться против часовой стрелки',clock:'Повернуть по часовой стрелке',degree:'Введите степень вращения',
            forward:'Принести вперед',backward:'Отправить назад',lock:'Если отмечено галочкой, позиция заблокирована',lockT:'Замок',noPrint:'Если этот флажок установлен, это поле не будет печататься',noPrintT:'Нет печати',
            newBarcode:'СОЗДАТЬ НОВЫЙ БАРКОД'},

        'imageLayer':{name:'Имя для этого поля изображения',browse:'Найдите файл изображения',width:'Выберите ширину',height:'Выберите высоту',
            aspect:'Заблокируйте соотношение сторон',stretch:'Позвольте растянуться',spL:'Показывает позицию слева',spT:'Показывает позицию сверху',
            pLeft:'Позиция слева',pTop:'Позиция сверху', mR:'Двигаться вправо',mL:'Двигай влево',mU:'Двигаться вверх',
            mD:'Двигаться вниз',cclock:'Вращаться против часовой стрелки',clock:'Повернуть по часовой стрелке',degree:'Введите степень вращения',
            forward:'Принести вперед',backward:'Отправить назад',lock:'Если отмечено галочкой, позиция заблокирована',lockT:'Замок',
            noPrint:'Если этот флажок установлен, изображение не будет печататься.',noPrintT:'Нет печати',newImage:'ДОБАВИТЬ ИЗОБРАЖЕНИЕ'},

        'shapeLayer':{name:'Имя для этого поля формы', width:'Выберите ширину',height:'Выберите высоту', aspect:'Заблокируйте соотношение сторон',
            stretch:'Позвольте растянуться', line:'Создать линию',circle:'Создайте круг или эллипс',square:'Создайте квадрат или прямоугольник',thick:'Выберите толщину линии',
            brColor:'Введите цвет границы',fillColor:'Введите цвет заливки',
            pLeft:'Позиция слева',pTop:'Позиция сверху', mR:'Двигаться вправо',mL:'Двигай влево',mU:'Двигаться вверх',
            mD:'Двигаться вниз',cclock:'Вращаться против часовой стрелки',clock:'Повернуть по часовой стрелке',degree:'Введите степень вращения',
            forward:'Принести вперед',backward:'Отправить назад',lock:'Если отмечено галочкой, позиция заблокирована',lockT:'Замок',
            noPrint:'Если поставить галочку, фигура не будет печататься.',noPrintT:'Нет печати',newShape:'СОЗДАЙТЕ НОВУЮ ФОРМУ'},

        'leftTab':{label:'Создать или отредактировать новую этикетку',text:'Добавить или отредактировать текстовое поле',barcode:'Добавить или отредактировать поле штрих-кода',
            image:'Добавить или отредактировать поле изображения',shape:'Добавить или отредактировать фигуру',dataVar:'Выберите переменные свойства поля',
            excel:'Связывание поля с текстовым файлом или листом Excel'},

        'popup':{paste:'ПАСТА ВАРИАНТЫ',uniquePaste:'Введите уникальное имя для вставленного поля',cancel:'ОТМЕНИТЬ',ok:'OK',
            stamp:'Штамп с датой',stampFormat:'Выберите формат штампа даты',apply:'ПРИМЕНЯТЬ',error:'ОШИБКА',uniqueError:'Имя поля должно быть уникальным',
            imageError:'Ошибка загрузки изображения',saveChange:'Сохранить изменения ?',saveDecide:'Вы хотите сохранить изменения ?',yes:'да',no:'Нет',
            saveLabel:'Вы хотите сохранить текущий лейбл?',qPrint:'Вопрос перед печатью',qty:'Количество',printPrev:'ПЕРЕПЕЧАТАТЬ ПРЕДЫДУЩИЙ',
            addList:'ДОБАВИТЬ В СПИСОК',print:'РАСПЕЧАТАТЬ',settings:'НастройкиНастройки',lang:'ЯЗЫК',setTrans:'УСТАНОВИТЬ ПЕРЕВОДЫ',rotate:'Повернутое поле',
            moveRotate:'Для перемещения или редактирования повернутого текста используйте элементы управления на боковой панели.',saveAppendCSV:'Ваш csv-файл обновлен. Сохранить обновленный файл?',
            saveResetCSV:'Новый файл csv имеет другие столбцы. Ссылки на данные будут потеряны. Сохранить новый файл?',important: 'Важный !',csvUpdate: 'Файл обновлен. Теперь свяжите поля на этикетке с новыми данными столбца'},
    },


    'Italian':{'Toolbar':{copy:'Copia',paste:'Incolla',delete:'Elimina un campo',zIn:'Ingrandire',zOut:'Zoom indietro',
            mD:'Sposta tutti i campi in basso',mU:'Sposta tutti i campi in alto',mL:'Sposta tutti i campi a sinistra',mR:'Sposta tutti i campi a destra',
            batch:'Crea un nuovo lotto di stampa',settings:'Impostazioni',help:'Aiuto',label:'ETICHETTA',data:'DATI'},

        'drawLabel':{editFile:'Modifica il nome del file',createL:'Crea un nuovo layout',open:'Apri un layout esistente',
            save:'Salva il layout corrente',width:'Immettere la larghezza della pagina o del rotolo',height:'Immettere l\'altezza della pagina (fogli) o l\'altezza dell\'etichetta (rotoli)',
            pagesz:'Crea o modifica le dimensioni di una pagina',apply:'APPLICARE'},

        'textLayer':{name:'Nome per questo campo di testo',style:'Seleziona uno stile di carattere',size:'Seleziona una dimensione del carattere',
            bold:'Testo grassetto',italic:'Testo in corsivo',strike:'Testo barrato',underline:'Testo sottolineato',
            invert:'Inverti testo',transparent:' Testo trasparente',left:'Allinea a sinistra',center:'Allineamento al centro',right:'Allineamento a destra',
            multiline:'Testo multilinea',tColor:'Colore del testo',bColor:'Colore di sfondo',maxW:'Larghezza massima multilinea',
            vLine:'Spazio linea verticale multilinea',pLeft:'Posizione da sinistra',pTop:'Posizione dall\'alto',mR:'Vai a destra',
            mL:'Muovere a sinistra',mU:'Andare avanti',mD:'Abbassati',cclock:'Ruotare in senso antiorario',clock:'Ruotare in senso orario',
            degree:'Immettere il grado di rotazione',forward:'Portare avanti',backward:'Manda indietro',lock:'Se spuntato la posizione è bloccata',lockT:'Serratura',
            noPrint:'Se spuntato, questo campo non verrà stampato',noPrintT:'Nessuna stampa',newText:'CREA NUOVO TESTO'},

        'barcodeLayer':{name:'Nome per questo campo del codice a barre',symbol:'Seleziona la simbologia del codice a barre',dpi:'Seleziona la risoluzione della stampante (dpi)',
            size:'Seleziona una dimensione del codice a barre',height:'Seleziona un\'altezza del codice a barre',invert:'Inverti il codice a barre',left:'Allinea a sinistra',center:'Allineamento al centro',
            right:'Allineamento a destra',bcdColor:'Colore del codice a barre',bColor:'Colore di sfondo',transparent:'Trasparente',pLeft:'Posizione da sinistra',pTop:'Posizione dall\'alto',
            mR:'Vai a destra',mL:'Muovere a sinistra',mU:'Andare avanti',mD:'Abbassati',cclock:'Ruotare in senso antiorario',clock:'Ruotare in senso orario',degree:'Immettere il grado di rotazione',
            forward:'Portare avanti',backward:'Manda indietro',lock:'Se spuntato la posizione è bloccata',lockT:'Serratura',noPrint:'Se spuntato, questo campo non verrà stampato',noPrintT:'Nessuna stampa',
            newBarcode:'CREA NUOVO CODICE A BARRE'},

        'imageLayer':{name:'Nome per questo campo immagine',browse:'Cerca un file immagine',width:'Seleziona la larghezza',height:'Seleziona l\'altezza',
            aspect:'Blocca le proporzioni',stretch:'Lasciar allungare',spL:'Mostra la posizione da sinistra',spT:'Mostra la posizione dall\'alto',
            pLeft:'Posizione da sinistra',pTop:'Posizione dall\'alto', mR:'Vai a destra',mL:'Muovere a sinistra',
            mU:'Andare avanti',mD:'Abbassati',cclock:'Ruotare in senso antiorario',clock:'Ruotare in senso orario',degree:'Immettere il grado di rotazione',
            forward:'Portare avanti',backward:'Manda indietro',lock:'Se spuntato la posizione è bloccata',lockT:'Serratura',
            noPrint:'Se spuntato l\'immagine non verrà stampata',noPrintT:'Nessuna stampa',newImage:'AGGIUNGI IMMAGINE'},

        'shapeLayer':{name:'Nome per questo campo forma',
            width:'Seleziona la larghezza',height:'Seleziona l\'altezza', aspect:'Blocca le proporzioni',stretch:'Lasciar allungare',
            line:'Crea una linea',circle:'Crea un cerchio o un\'ellisse',square:'Crea un quadrato o un rettangolo',thick:'Seleziona lo spessore della linea',
            brColor:'Inserisci il colore del bordo',fillColor:'Immettere il colore di riempimento',
            pLeft:'Posizione da sinistra',pTop:'Posizione dall\'alto', mR:'Vai a destra',mL:'Muovere a sinistra',
            mU:'Andare avanti',mD:'Abbassati',cclock:'Ruotare in senso antiorario',clock:'Ruotare in senso orario',degree:'Immettere il grado di rotazione',
            forward:'Portare avanti',backward:'Manda indietro',lock:'Se spuntato la posizione è bloccata',lockT:'Serratura',
            noPrint:'Se spuntato, la forma non verrà stampata',noPrintT:'Nessuna stampa',newShape:'CREA NUOVA FORMA'},

        'leftTab':{label:'Crea o modifica una nuova etichetta',text:'Aggiungi o modifica un campo di testo',barcode:'Aggiungi o modifica un campo di codice a barre',
            image:'Aggiungi o modifica un campo immagine',shape:'Aggiungi o modifica una forma',dataVar:'Seleziona Proprietà variabili di un campo',
            excel:'Collega un campo a un file di testo o un foglio Excel'},


        'popup':{paste:'OPZIONI DI PASTA',uniquePaste:'Immettere un nome univoco per il campo incollato',cancel:'ANNULLA',ok:'OK',
            stamp:'Il timbro della data',stampFormat:'Scegli un formato per il timbro della data',apply:'APPLICARE',error:'ERRORE',uniqueError:'Il nome del campo deve essere univoco',
            imageError:'Errore di caricamento dell\'immagine',saveChange:'Salvare le modifiche ?',saveDecide:'Vuoi salvare le modifiche ?',yes:'sì',no:'NO',
            saveLabel:'Vuoi salvare l\'etichetta corrente?',qPrint:'Domanda prima della stampa',qty:'Quantità',printPrev:'RISTAMPA PRECEDENTE',
            addList:'AGGIUNGI ALLA LISTA',print:'STAMPA',settings:'impostazioni',lang:'LINGUAGGIO',setTrans:'SET TRADUZIONI',rotate:'Campo ruotato',
            moveRotate:'Per spostare o modificare il testo ruotato utilizza i controlli della barra laterale',saveAppendCSV:'Il tuo file csv è stato aggiornato. Vuoi salvare il file aggiornato?',
            saveResetCSV:'Il nuovo file csv ha colonne diverse. I collegamenti dati andranno persi. Salvare il nuovo file?',important: 'Importante !',csvUpdate: 'File aggiornato. Ora collega i campi sulla tua etichetta ai nuovi dati della colonna'},
    },

    'Vietnamese':{'Toolbar':{copy:'Sao chép',paste:'Dán',delete:'Xóa một trường',zIn:'Phóng to',zOut:'Thu nhỏ',
            mD:'Di chuyển tất cả xuống',mU:'Di chuyển tất cả lên',mL:'Di chuyển tất cả sang trái',mR:'Di chuyển tất cả các bên phải',
            batch:'Tạo một lô in mới',settings:'Cài đặt',help:'Cứu giúp',label:'NHÃN',data:'DỮ LIỆU'},

        'drawLabel':{editFile:'Chỉnh sửa tên tệp',createL:'Tạo một bố cục mới',open:'Mở một bố cục hiện có',
            save:'Lưu bố cục hiện tại',width:'Nhập trang hoặc chiều rộng cuộn',height:'Nhập chiều cao trang (tờ) hoặc chiều cao nhãn (cuộn)',
            pagesz:'Tạo hoặc chỉnh sửa kích thước trang',apply:'ỨNG DỤNG'},

        'textLayer':{name:'Tên cho trường văn bản này',style:'Chọn một kiểu phông chữ',size:'Chọn kích thước phông chữ',
            bold:'Chữ in đậm',italic:'Văn bản in nghiêng',strike:'Đánh dấu qua văn bản',underline:'Văn bản được gạch chân',
            invert:'Đảo ngược văn bản',transparent:'Văn bản trong suốt',left:'Căn trái',center:'Căn giữa',right:'Căn phải',
            multiline:'Văn bản nhiều dòng',tColor:'Văn bản màu',bColor:'Màu nền',maxW:'Chiều rộng tối đa của nhiều dòng',
            vLine:'Không gian dòng dọc nhiều dòng',pLeft:'Vị trí từ trái',pTop:'Vị trí từ trên xuống',mR:'Đi sang phải',
            mL:'Di chuyển sang trái',mU:'Đi lên',mD:'Đi xuống',cclock:'Xoay ngược chiều kim đồng hồ',clock:'Xoay theo chiều kim đồng hồ',
            degree:'Nhập mức độ xoay',forward:'Mang về phía trước',backward:'Gửi trở lại',lock:'Nếu được đánh dấu, vị trí sẽ bị khóa',lockT:'Khóa',
            noPrint:'Nếu được đánh dấu, trường này sẽ không in',noPrintT:'Không in',newText:'TẠO VĂN BẢN MỚI'},

        'barcodeLayer':{name:'Tên cho trường mã vạch này',symbol:'Chọn ký hiệu mã vạch',dpi:'Chọn độ phân giải máy in của bạn (dpi)',
            size:'Chọn kích thước mã vạch',height:'Chọn chiều cao mã vạch',invert:'Đảo ngược mã vạch',left:'Căn trái',center:'Căn giữa',
            right:'Căn phải',bcdColor:'Màu mã vạch',bColor:'Màu nền',transparent:'Trong suốt',pLeft:'Vị trí từ trái',pTop:'Vị trí từ trên xuống',
            mR:'Đi sang phải',mL:'Di chuyển sang trái',mU:'Đi lên',mD:'Đi xuống',
            cclock:'Xoay ngược chiều kim đồng hồ',clock:'Xoay theo chiều kim đồng hồ', degree:'Nhập mức độ xoay',forward:'Mang về phía trước',
            backward:'Gửi trở lại',lock:'Nếu được đánh dấu, vị trí sẽ bị khóa',lockT:'Khóa',
            noPrint:'Nếu được đánh dấu, trường này sẽ không in',noPrintT:'Không in', newBarcode:'TẠO MÃ VẠCH MỚI'},

        'imageLayer':{name:'Đặt tên cho trường ảnh này',browse:'Duyệt tìm tệp ảnh',width:'Chọn chiều rộng',height:'Chọn chiều cao',
            aspect:'Khóa tỷ lệ khung hình',stretch:'Cho phép kéo dài',spL:'Hiển thị vị trí từ bên trái',spT:'Hiển thị vị trí từ trên xuống',
            pLeft:'Vị trí từ trái',pTop:'Vị trí từ trên xuống', mR:'Đi sang phải',mL:'Di chuyển sang trái',mU:'Đi lên',mD:'Đi xuống',
            cclock:'Xoay ngược chiều kim đồng hồ',clock:'Xoay theo chiều kim đồng hồ', degree:'Nhập mức độ xoay',forward:'Mang về phía trước',
            backward:'Gửi trở lại',lock:'Nếu được đánh dấu, vị trí sẽ bị khóa',lockT:'Khóa', noPrint:'Nếu được đánh dấu, hình ảnh sẽ không in',noPrintT:'Không in',
            newImage:'THÊM HÌNH ẢNH'},

        'shapeLayer':{name:'Đặt tên cho trường hình dạng này',width:'Chọn chiều rộng',height:'Chọn chiều cao', aspect:'Khóa tỷ lệ khung hình',stretch:'Cho phép kéo dài',
            line:'Tạo một dòng',circle:'Tạo hình tròn hoặc hình elip',square:'Tạo hình vuông hoặc hình chữ nhật',thick:'Chọn độ dày của đường',
            brColor:'Nhập màu đường viền',fillColor:'Nhập màu tô',
            pLeft:'Vị trí từ trái',pTop:'Vị trí từ trên xuống', mR:'Đi sang phải',mL:'Di chuyển sang trái',mU:'Đi lên',mD:'Đi xuống',
            cclock:'Xoay ngược chiều kim đồng hồ',clock:'Xoay theo chiều kim đồng hồ', degree:'Nhập mức độ xoay',forward:'Mang về phía trước',
            backward:'Gửi trở lại',lock:'Nếu được đánh dấu, vị trí sẽ bị khóa',lockT:'Khóa',
            noPrint:'Nếu được đánh dấu, hình dạng sẽ không in',noPrintT:'Không in',newShape:'TẠO HÌNH DẠNG MỚI'},

        'leftTab':{label:'Tạo hoặc chỉnh sửa nhãn mới',text:'Thêm hoặc chỉnh sửa trường văn bản',barcode:'Thêm hoặc chỉnh sửa trường mã vạch',
            image:'Thêm hoặc chỉnh sửa trường hình ảnh',shape:'Thêm hoặc chỉnh sửa hình dạng',dataVar:'Chọn thuộc tính biến của một trường',
            excel:'Liên kết Trường với Tệp Văn bản hoặc Trang tính Excel'},

        'popup':{paste:'TÙY CHỌN QUÁ KHỨ',uniquePaste:'Nhập tên duy nhất cho trường đã dán',cancel:'HỦY',ok:'đồng ý',
            stamp:'Dấu ngày tháng',stampFormat:'Chọn định dạng dấu ngày tháng',apply:'ỨNG DỤNG',error:'LỖI',uniqueError:'Tên trường phải là duy nhất',
            imageError:'Lỗi tải lên hình ảnh',saveChange:'Lưu thay đổi ?',saveDecide:'Bạn có muốn lưu các thay đổi ?',yes:'Đúng',no:'Không',
            saveLabel:'Bạn có muốn lưu nhãn hiện tại không ?',qPrint:'Câu hỏi trước khi in',qty:'Định lượng',printPrev:'REPRINT TRƯỚC',
            addList:'THÊM VÀO DANH SÁCH',print:'IN',settings:'Cài đặt',lang:'NGÔN NGỮ',setTrans:'ĐẶT BẢN DỊCH',rotate:'Trường xoay',
            moveRotate:'Để di chuyển hoặc chỉnh sửa văn bản được xoay, hãy sử dụng các điều khiển thanh bên',saveAppendCSV:'Tệp csv của bạn đã được cập nhật. Lưu tệp đã cập nhật?',
            saveResetCSV:'Tệp csv mới có các cột khác nhau. Liên kết dữ liệu sẽ bị mất. Lưu tệp mới?',important: 'Quan trọng !',csvUpdate: 'Tệp đã được cập nhật. Bây giờ hãy liên kết các trường trên nhãn của bạn với dữ liệu cột mới'},


    },


}

export default language_Options
